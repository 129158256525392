import React, { FC, useState } from 'react'
import dynamic from 'next/dynamic'
import { Box, Dialog, DialogContent, DialogTitle, Divider, Typography } from '@mui/material'
import { ReactComponent as ArrowBackIcon } from 'assets/icon/designsystem/arrow_back.svg'
import { ReactComponent as AddShoppingCartIcon } from '@obeta/assets/icon/add_shopping_cart.svg'
import { useTranslation } from 'react-i18next'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { ShoppingCartItem } from '@obeta/models/lib/models'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { buildSearchUrlWithDehaIds } from '@obeta/utils/lib/search/buildSearchUrlWithDehaIds'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import { PrimaryButton, SecondaryButton } from '../custom-button/CustomButton'
import { ShopLink } from '../link/ShopLink'
import styles from './ReplacementArticleDialog.module.scss'
import {
  ReplacementAmountItem,
  useReplacementDialog,
} from '@obeta/data/lib/hooks/useReplacementDialog'
import { ICartItemForPdfProps, ICartItemProps } from '../product-card/makeCartItem'
import clsx from 'clsx'

const CartItem = dynamic(
  () =>
    import('../product-card').then((mod) => mod.CartItem) as Promise<
      FC<Omit<ICartItemProps & ICartItemForPdfProps, 'onTitleClick' | 'onProductImageClicked'>>
    >
)

type Props = {
  open: boolean
  onClose: () => void
  /**
   * @param items Only items that are relevant for the calculation are transferred, i.e. a maximum of 2.
   * @param itemOfChoice The item which gets replaced
   */
  onAddToCart?: (items: ShoppingCartItem[], itemOfChoice?: ShoppingCartItem) => void
  itemOfChoice: ShoppingCartItem
  showPurgeButtonForReplacementItem?: boolean
  showDeleteButtonForDiscontinuedItem?: boolean
  changeProductAmount?: (itemId: string, amount: number, sapId?: string) => void
  deleteCartItems?: (cartItems: ShoppingCartItem[]) => void
  setOpenReplacementArticleDialog?: (openDialog: boolean) => void
  setIndexToShowForDiscontinuedItem?: React.Dispatch<React.SetStateAction<number>>
  setMarkedItemsOfChoiceForDeletion?: React.Dispatch<React.SetStateAction<ShoppingCartItem[]>>
  itemsDiscontinuedWithPartialStock?: ShoppingCartItem[]
  setItemsDiscontinuedWithPartialStock?: React.Dispatch<React.SetStateAction<ShoppingCartItem[]>>
  setMarkedItemsOfChoiceToBeAddedWithAdaptedAmount?: React.Dispatch<
    React.SetStateAction<ShoppingCartItem[]>
  >
  setReplacementItemsToBeAddedToCart?: React.Dispatch<React.SetStateAction<ShoppingCartItem[]>>
  activeCartName: string
}

const MAX_AMOUNT_OF_REPLACEMENT_ITEMS = 2
const NUMBER_REPLACEMENT_ITEMS_TO_SHOW = 1

const DialogBottom: FC<{
  onAbort: () => void
  onAddToCart: () => void
  activeCartName: string
}> = ({ onAddToCart, onAbort, activeCartName }) => {
  const { t } = useTranslation()
  const { mobile } = useBreakpoints()

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-end" gap="0.875rem">
      <Typography display="flex" justifyContent="flex-end" sx={{ pt: 1 }}>
        {t('REPLACEMENT.DIALOG.ADD_TO_CART_DESCRIPTION')}
      </Typography>
      <Box display="flex" justifyContent={mobile ? 'space-between' : 'flex-end'} gap="1.5rem">
        <SecondaryButton onClick={onAbort} leftIcon={mobile ? null : <CloseIcon />}>
          {mobile ? (
            <CloseIcon className={styles.mobileCloseIcon} />
          ) : (
            t('REPLACEMENT.DIALOG.ABORT')
          )}
        </SecondaryButton>
        <div className={styles.addToCart}>
          <PrimaryButton fullWidth leftIcon={<AddShoppingCartIcon />} onClick={onAddToCart}>
            {activeCartName}
          </PrimaryButton>
        </div>
      </Box>
    </Box>
  )
}

const ReplacementSection: FC<{
  changeProductAmount: (sapId: string, amount: number) => void
  onDeleteClicked: () => void
  replacementItems: ShoppingCartItem[]
  replacementAmounts: ReplacementAmountItem[]
  itemOfChoice: ShoppingCartItem
  showPurgeButtonForReplacementItem?: boolean
  setReplacementItems: (replacementItems: ShoppingCartItem[]) => void
  setShouldFetchReplacementItems: (shouldFetchReplacementItems: boolean) => void
}> = ({
  changeProductAmount,
  onDeleteClicked,
  replacementItems,
  replacementAmounts,
  itemOfChoice,
  showPurgeButtonForReplacementItem,
  setReplacementItems,
  setShouldFetchReplacementItems,
}) => {
  const { isLoggedIn, user } = useUserDataV2()

  const { mobile, tabletAll, tabletWide, desktop, tablet } = useBreakpoints()
  const { t } = useTranslation()

  const tabletDependentPadding = tablet ? 1 : 0
  const breakpointDependentPadding = desktop || tabletWide ? 1.5 : tabletDependentPadding
  const href = buildSearchUrlWithDehaIds(
    replacementItems.map(({ product }) => product.dehaId),
    t('REPLACEMENT.ALL_REPLACEMENT_ITEMS'),
    t('REPLACEMENT.FOR_PRODUCT', { id: itemOfChoice.product.dehaId })
  )
  const handlePurgeReplacementItem = () => {
    setReplacementItems([])
    setShouldFetchReplacementItems(false)
  }

  return (
    <Box>
      <Box
        sx={{ pl: breakpointDependentPadding, pt: 1, pb: 0.5 }}
        display="flex"
        justifyContent="space-between"
        className={mobile || tablet ? '' : styles.paddingLeftRightOneHalfRem}
      >
        {replacementItems.length > 0 && (
          <Typography variant="bodyBold">
            {t('REPLACEMENT.DIALOG.REPLACEMENT_COUNT', { count: replacementItems.length })}
          </Typography>
        )}
        {replacementItems.length > NUMBER_REPLACEMENT_ITEMS_TO_SHOW && href && (
          <ShopLink href={href} style={{ textDecoration: 'none' }}>
            <Typography variant="bodyBold">{t('REPLACEMENT.DIALOG.SHOW_ALL_ARTICLES')}</Typography>
          </ShopLink>
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        sx={{ px: breakpointDependentPadding, pt: 0 }}
        gap={mobile ? 0.5 : 1.5}
      >
        {replacementItems.slice(0, NUMBER_REPLACEMENT_ITEMS_TO_SHOW).map((item, index) => {
          let amount = 0
          const foundItem = replacementAmounts.find(({ sapId }) => item.sapId === sapId)
          if (foundItem) {
            amount = foundItem.amount
          }
          return (
            <CartItem
              shoppingCartItem={{
                ...item,
                amount,
              }}
              withCheckbox={false}
              changeProductAmount={(itemId: string, amount: number, sapId?: string) => {
                if (sapId) {
                  changeProductAmount(sapId, amount)
                }
              }}
              onDeleteClicked={onDeleteClicked}
              selectedStore={undefined}
              selected={false}
              user={user}
              isLoggedIn={isLoggedIn}
              mobile={mobile}
              tablet={tabletAll}
              productId={item.product.sapId}
              hideActionsElements={!showPurgeButtonForReplacementItem}
              showPurgeButtonForReplacementItem={showPurgeButtonForReplacementItem}
              onPurgeReplacementItem={handlePurgeReplacementItem}
              key={item.product.sapId}
              replacementItem={true}
              url={
                item.product.images && item.product.images?.length > 0
                  ? item.product.images[0].url
                  : ''
              }
            />
          )
        })}
      </Box>
    </Box>
  )
}

const MobileStickyHeader: FC<{ onGoBack: () => void }> = ({ onGoBack }) => {
  const { mobile } = useBreakpoints()
  const { t } = useTranslation()
  if (!mobile) return null
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <Box className={styles.mobileMenu}>
      <Box
        onClick={onGoBack}
        className={styles.mobileBackButton}
        display="flex"
        alignItems="center"
        gap="0.5rem"
        sx={{ py: 1 }}
      >
        <ArrowBackIcon className={styles.mobileBackIcon} />
        <Typography variant="bodyBold">{t('REPLACEMENT.DIALOG.GO_BACK')}</Typography>
      </Box>
      <Divider sx={{ mx: 0 }} variant="middle" />
    </Box>
  )
}

export const ReplacementArticleDialog: FC<Props> = ({
  open,
  onClose,
  itemOfChoice,
  showDeleteButtonForDiscontinuedItem,
  changeProductAmount,
  setOpenReplacementArticleDialog,
  setMarkedItemsOfChoiceForDeletion,
  itemsDiscontinuedWithPartialStock,
  setItemsDiscontinuedWithPartialStock,
  setMarkedItemsOfChoiceToBeAddedWithAdaptedAmount,
  setReplacementItemsToBeAddedToCart,
  onAddToCart,
  activeCartName,
}) => {
  const {
    title,
    description,
    disableItemOfChoice,
    itemOfChoiceAmount,
    replacementAmounts,
    replacementItems,
    changeReplacementItemAmount,
    setReplacementItems,
    setShouldFetchReplacementItems,
  } = useReplacementDialog({
    itemOfChoice,
  })

  const { isLoggedIn, user } = useUserDataV2()
  const { mobile, tabletAll, tabletWide, tablet } = useBreakpoints()
  const { t } = useTranslation()
  const [maskItemOfChoice, setMaskItemOfChoice] = useState<boolean>(false)

  const handleDeleteItemOfChoice = (productId: string | undefined) => {
    //Upon deleting item of choice, the amount of the replacement is set to the original amount.
    if (replacementItems.length > 0) {
      //TODO: If ever the case with more than one replacementItem should be implemented, the code here might need some changes.
      changeReplacementItemAmount(
        replacementItems[0].sapId,
        itemOfChoice.amount,
        replacementItems.slice(0, MAX_AMOUNT_OF_REPLACEMENT_ITEMS)
      )
    }

    setMaskItemOfChoice(true)
    setMarkedItemsOfChoiceForDeletion &&
      setMarkedItemsOfChoiceForDeletion((oldItems) => {
        return oldItems.concat([itemOfChoice])
      })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={mobile}
      className={clsx(
        styles.dialogWrapper,
        tabletWide && styles.dialogTabletWide,
        tablet && styles.dialogTabletSmall
      )}
      maxWidth="lg"
    >
      <Box className={styles.dialog}>
        <MobileStickyHeader onGoBack={onClose} />
        <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
          <DialogTitle sx={{ pt: mobile ? 3 : 0, pb: 0.5 }}>{title}</DialogTitle>
          {description && <Typography>{description}</Typography>}
        </Box>
        <DialogContent sx={{ px: 0, pb: 0, pt: 1 }}>
          <Box sx={{ px: 0.125 }}>
            <Typography variant="bodyBold">{t('REPLACEMENT.DIALOG.ITEM_OF_CHOICE')}</Typography>
            <Box sx={{ pt: 1 }}>
              <Box sx={{ opacity: disableItemOfChoice ? 0.5 : 1 }}>
                {!maskItemOfChoice && (
                  <CartItem
                    shoppingCartItem={{
                      ...itemOfChoice,
                      amount: itemOfChoiceAmount,
                      product: { ...itemOfChoice.product, minimumAmount: itemOfChoiceAmount },
                    }}
                    withCheckbox={false}
                    changeProductAmount={
                      //Intentionally, passing an undefined function here to prevent change in amount for item of choice
                      changeProductAmount as (
                        itemId: string,
                        amount: number,
                        sapId?: string
                      ) => void
                    }
                    onDeleteClicked={() => {
                      // Not applicable to this type of product card, maybe needed in COD-15256
                    }}
                    selectedStore={undefined}
                    selected={false}
                    user={user}
                    isLoggedIn={isLoggedIn}
                    mobile={mobile}
                    tablet={tabletAll}
                    productId={itemOfChoice.product.sapId}
                    hideActionsElements={!showDeleteButtonForDiscontinuedItem}
                    disableCounter={disableItemOfChoice}
                    showDeleteButtonForDiscontinuedItem={showDeleteButtonForDiscontinuedItem}
                    onDeleteItemOfChoice={handleDeleteItemOfChoice}
                    hideImage={mobile ? disableItemOfChoice : false}
                    showStockData={mobile ? !disableItemOfChoice : true}
                    showPriceData={mobile ? !disableItemOfChoice : true}
                    url={
                      itemOfChoice.product.images && itemOfChoice.product.images?.length > 0
                        ? itemOfChoice.product.images[0].url
                        : ''
                    }
                  />
                )}
              </Box>
            </Box>
            <ReplacementSection
              replacementItems={replacementItems.slice(0, MAX_AMOUNT_OF_REPLACEMENT_ITEMS)}
              replacementAmounts={replacementAmounts}
              itemOfChoice={itemOfChoice}
              changeProductAmount={(sapId, amount) => {
                changeReplacementItemAmount(
                  sapId,
                  amount,
                  replacementItems.slice(0, MAX_AMOUNT_OF_REPLACEMENT_ITEMS)
                )
              }}
              onDeleteClicked={() => {
                // Not applicable to this type of product card, maybe needed in COD-15256
              }}
              showPurgeButtonForReplacementItem={showDeleteButtonForDiscontinuedItem}
              setReplacementItems={setReplacementItems}
              setShouldFetchReplacementItems={setShouldFetchReplacementItems}
            />
          </Box>

          <DialogBottom
            onAbort={onClose}
            onAddToCart={() => {
              const itemsAmountsFromDialog = replacementItems
                .slice(0, NUMBER_REPLACEMENT_ITEMS_TO_SHOW)
                .map((item) => {
                  const amountItem = replacementAmounts.find(({ sapId }) => sapId === item.sapId)
                  if (!amountItem) return item
                  return { ...item, amount: amountItem.amount }
                })

              if (onAddToCart) {
                onAddToCart(itemsAmountsFromDialog, itemOfChoice)
              } else {
                setReplacementItemsToBeAddedToCart &&
                  setReplacementItemsToBeAddedToCart((oldItems) => {
                    return oldItems.concat(itemsAmountsFromDialog)
                  })

                if (!maskItemOfChoice) {
                  const itemOfChoiceWithUpdatedAmount = {
                    ...itemOfChoice,
                    amount: itemOfChoiceAmount,
                    product: { ...itemOfChoice.product, minimumAmount: itemOfChoiceAmount },
                  }
                  setMarkedItemsOfChoiceForDeletion &&
                    setMarkedItemsOfChoiceForDeletion((oldItems) => {
                      return oldItems.concat([itemOfChoice])
                    })

                  setMarkedItemsOfChoiceToBeAddedWithAdaptedAmount &&
                    setMarkedItemsOfChoiceToBeAddedWithAdaptedAmount((oldItems) => {
                      return oldItems.concat([itemOfChoiceWithUpdatedAmount])
                    })
                }

                const remainingItems = itemsDiscontinuedWithPartialStock?.filter(
                  (item) => item.product.sapId !== itemOfChoice.product.sapId
                )

                setItemsDiscontinuedWithPartialStock &&
                  setItemsDiscontinuedWithPartialStock(remainingItems as ShoppingCartItem[])
                setOpenReplacementArticleDialog && setOpenReplacementArticleDialog(false)
              }
            }}
            activeCartName={activeCartName}
          />
        </DialogContent>
      </Box>
    </Dialog>
  )
}
